var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cw-page',{staticClass:"cw-bank-account-selection",attrs:{"icon":"savings"},scopedSlots:_vm._u([{key:"hero-title",fn:function(){return [_c('translate',[_vm._v(" Your loan offer is just a few steps away ")])]},proxy:true},{key:"actions",fn:function(){return [_c('v-spacer'),_c('v-btn',{attrs:{"id":"submit","disabled":_vm.$wait.waiting('SUBMIT_FORM')
        || !_vm.initialData
        || !_vm.initialData.possibleBankAccounts
        || !_vm.initialData.possibleBankAccounts.length,"loading":_vm.$wait.waiting('SUBMIT_FORM'),"dark":!_vm.$wait.waiting('SUBMIT_FORM')
        && _vm.initialData
        && _vm.initialData.possibleBankAccounts
        && _vm.initialData.possibleBankAccounts.length > 0,"color":"purple-one darken-2"},on:{"click":function($event){_vm.submit();
        _vm.$eventLogger.clickEvent($event);}}},[_c('translate',[_vm._v("Continue")])],1)]},proxy:true}])},[_c('validation-observer',{ref:"observer",attrs:{"autocomplete":"off","tag":"v-form"}},[_c('v-row',{staticClass:"relative"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"ma-0"},[_c('translate',[_vm._v(" Please select the account you want the money to be transferred to. ")]),_c('translate',[_vm._v(" In case of issues, please contact our customer support by phone or use the chat function on our website. ")])],1)]),(_vm.initialData.possibleBankAccounts && _vm.initialData.possibleBankAccounts.length)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"ma-0"},[_c('translate',[_vm._v(" The following bank accounts were found. ")])],1),_c('ValidationProvider',{ref:"provider",attrs:{"events":"change","name":_vm.fieldName,"rules":_vm.rules,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var validate = ref.validate;
return [_c('v-radio-group',{staticClass:"mt-0",attrs:{"mandatory":!!_vm.form.iban,"error-messages":errors},model:{value:(_vm.form.iban),callback:function ($$v) {_vm.$set(_vm.form, "iban", $$v)},expression:"form.iban"}},_vm._l((_vm.initialData.possibleBankAccounts),function(item,i){return _c('div',{key:("accountNumber-" + i),staticClass:"cw-bank-account-selection__item",class:{
                'cw-bank-account-selection__item--active primary--text':
                  _vm.form.iban === item.accountNumber
              }},[_c('v-radio',{staticClass:"px-2 py-3",attrs:{"id":("cw-bank-account-selection__item--" + i),"value":item.accountNumber},on:{"click":function($event){_vm.onChange(validate)
                  _vm.logOnClick(
                    $event,
                    item.accountNumber,
                    'iban'
                  );}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"pl-2"},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.splitToParts(item.accountNumber)))])])]},proxy:true}],null,true)})],1)}),0)]}}],null,false,560669120)})],1):_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-alert',{attrs:{"border":"left","type":"error","dense":"","text":""}},[_c('translate',[_vm._v(" Unfortunately no bank accounts were found. ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }