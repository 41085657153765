<template>
  <cw-page
    icon="savings"
    class="cw-bank-account-selection"
  >
    <template #hero-title>
      <translate>
        Your loan offer is just a few steps away
      </translate>
    </template>

    <validation-observer
      ref="observer"
      autocomplete="off"
      tag="v-form"
    >
      <v-row class="relative">
        <v-col
          cols="12"
          md="6"
        >
          <p class="ma-0">
            <translate>
              Please select the account you want the money to be transferred to.
            </translate>
            <translate>
              In case of issues, please contact our customer support by phone or use the chat
              function on our website.
            </translate>
          </p>
        </v-col>
        <v-col
          v-if="initialData.possibleBankAccounts && initialData.possibleBankAccounts.length"
          cols="12"
          md="6"
        >
          <p class="ma-0">
            <translate>
              The following bank accounts were found.
            </translate>
          </p>
          <ValidationProvider
            v-slot="{ errors, validate }"
            ref="provider"
            events="change"
            :name="fieldName"
            :rules="rules"
            tag="div"
          >
            <v-radio-group
              v-model="form.iban"
              :mandatory="!!form.iban"
              :error-messages="errors"
              class="mt-0"
            >
              <div
                v-for="(item, i) in initialData.possibleBankAccounts"
                :key="`accountNumber-${i}`"
                :class="{
                  'cw-bank-account-selection__item--active primary--text':
                    form.iban === item.accountNumber
                }"
                class="cw-bank-account-selection__item"
              >
                <v-radio
                  :id="`cw-bank-account-selection__item--${i}`"
                  :value="item.accountNumber"
                  class="px-2 py-3"
                  @click="
                    onChange(validate)
                    logOnClick(
                      $event,
                      item.accountNumber,
                      'iban'
                    );
                  "
                >
                  <template #label>
                    <div class="pl-2">
                      <span class="font-weight-medium">{{ splitToParts(item.accountNumber) }}</span>
                    </div>
                  </template>
                </v-radio>
              </div>
            </v-radio-group>
          </ValidationProvider>
        </v-col>

        <v-col
          v-else
          cols="12"
          md="6"
        >
          <v-alert
            border="left"
            type="error"
            dense
            text
          >
            <translate>
              Unfortunately no bank accounts were found.
            </translate>
          </v-alert>
        </v-col>
      </v-row>
    </validation-observer>

    <template #actions>
      <v-spacer/>
      <v-btn
        id="submit"
        :disabled="$wait.waiting('SUBMIT_FORM')
          || !initialData
          || !initialData.possibleBankAccounts
          || !initialData.possibleBankAccounts.length"
        :loading="$wait.waiting('SUBMIT_FORM')"
        :dark="!$wait.waiting('SUBMIT_FORM')
          && initialData
          && initialData.possibleBankAccounts
          && initialData.possibleBankAccounts.length > 0"
        color="purple-one darken-2"
        @click="
          submit();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>Continue</translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapActions, mapGetters } from 'vuex';
import { goToError } from '@shared/mixins';
import { DataLayer } from '@shared/services/dataLayer';

export default {
  name: 'CwBankAccountSelection',

  components: {
    'validation-observer': ValidationObserver,
    ValidationProvider,
  },

  mixins: [goToError],

  data: () => ({
    form: {
      iban: null,
    },
  }),

  computed: {
    ...mapGetters({
      apiErrors: 'application/apiErrors',
      initialData: 'application/getInitialData',
    }),

    fieldName() {
      return 'iban';
    },

    hasApiError() {
      return this.apiErrors.includes(this.fieldName);
    },

    /*
     * Extends the rules set with api errors
     */
    rules() {
      return `required|error:${this.hasApiError}`;
    },
  },

  watch: {
    'form.iban': {
      handler(value) {
        if (!value) return;

        this.logOnChange(value, this.fieldName);
      },
    },
  },

  async mounted() {
    this.setInitialValue();
  },

  methods: {
    ...mapActions({
      removeApiError: 'application/removeApiError',
      submitForm: 'application/submit',
    }),

    getElementData(value, id) {
      return {
        targetId: id,
        name: id,
        nodeName: 'INPUT',
        type: 'radio',
        value: String(value),
      };
    },

    logOnChange(value, id) {
      const data = this.getElementData(value, id);

      this.$eventLogger.vuetifyEvent({ type: 'change' }, data, { anonymize: true });
    },

    logOnClick(event, value, id) {
      const data = this.getElementData(value, id);

      this.$eventLogger.vuetifyEvent(event, data, { anonymize: true });
    },

    setInitialValue() {
      if (this.initialData.iban) {
        this.form.iban = this.initialData.iban;
      }
    },

    splitToParts(value) {
      return value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
    },

    async onChange() {
      this.removeApiError(this.fieldName);

      await this.$nextTick();
      const { valid } = await this.$refs.provider.validate();

      DataLayer.onValidationClick();

      if (!valid) {
        this.goToError();
        DataLayer.onValidationFail();
        this.$wait.end('SUBMIT_FORM');
      }

      return valid;
    },

    async submit() {
      this.$wait.start('SUBMIT_FORM');

      const valid = await this.onChange();

      if (!valid) return;

      const formParams = { ...this.form };
      const routineParams = {
        uri: 'executeRoutine',
        routine: 'Submit Bank Account Selection',
      };

      try {
        await this.submitForm({
          formParams,
          routineParams,
        });
      } catch (e) {
        this.$wait.end('SUBMIT_FORM');
      }
    },
  },
};
</script>

<style lang="scss">
.cw-bank-account-selection {
  --selection-border-color: transparent;

  &__item {
    position: relative;
    border: 1px solid var(--selection-border-color);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 4px;

    &::before {
      background-color: currentColor;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.03;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid currentColor;
      opacity: 0.12;
      border-radius: 8px;
      pointer-events: none;
    }

    &--active {
      --selection-border-color: currentColor;

      &::before {
        opacity: 0.12;
      }

      &::after {
        opacity: 0;
      }
    }
  }
}
</style>
